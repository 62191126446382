import { Example } from "./Example";

import styles from "./Example.module.css";

export type ExampleModel = {
    text: string;
    value: string;
};

let EXAMPLES: ExampleModel[] = [
    // bchile
    // { text: "Cómo se cierra una tarjeta de credito por CDN?", value: "Cómo se cierra una tarjeta de credito por CDN?" },
    // { text: "Cuáles son las condiciones para obtener cuenta FAN Emprende?", value: "Cuáles son las condiciones para obtener cuenta FAN Emprende?" },
    // { text: "Dónde estará disponible la declaración FINTEC para Inversiones?", value: "Dónde estará disponible la declaración FINTEC para Inversiones?" }

    // corfo
    { text: "dónde puedo realizar consultas?", value: "dónde puedo realizar consultas?" },
    { text: "hay charlas online?", value: "hay charlas online?" },
    { text: "Tengo una persona jurídica constituida que ya tiene ventas, pero son de otro giro, diferente al producto/servicio considerado en el proyecto, ¿Puedo postular?", value: "Tengo una persona jurídica constituida que ya tiene ventas, pero son de otro giro, diferente al producto/servicio considerado en el proyecto, ¿Puedo postular?" },
];

interface Props {
    onExampleClicked: (value: string) => void;
}

export const ExampleList = ({ onExampleClicked }: Props) => {
    EXAMPLES = [];
    const hostname = window.location.hostname;

    if (hostname.includes("consorcio")) {
        EXAMPLES.push({ text: "qué tipos de seguros hay?", value: "qué tipos de seguros hay?" });
        EXAMPLES.push({ text: "resumen de seguro de deportes", value: "resumen de seguro de deportes" });
        EXAMPLES.push({ text: "seguro hogar", value: "seguro hogar" });
    }
    else if (hostname.includes("corfo")) {
        EXAMPLES.push({ text: "dónde puedo realizar consultas?", value: "dónde puedo realizar consultas?" });
        EXAMPLES.push({ text: "hay charlas online?", value: "hay charlas online?" });
        EXAMPLES.push({ text: "Tengo una persona jurídica constituida que ya tiene ventas, pero son de otro giro, diferente al producto/servicio considerado en el proyecto, ¿Puedo postular?", value: "Tengo una persona jurídica constituida que ya tiene ventas, pero son de otro giro, diferente al producto/servicio considerado en el proyecto, ¿Puedo postular?" });
    }
    else if (hostname.includes("bchile")) {
        EXAMPLES.push({ text: "Cómo se cierra una tarjeta de credito por CDN?", value: "Cómo se cierra una tarjeta de credito por CDN?" });
        EXAMPLES.push({ text: "Cuáles son las condiciones para obtener cuenta FAN Emprende??", value: "Cuáles son las condiciones para obtener cuenta FAN Emprende?" });
        EXAMPLES.push({ text: "Tengo una persona jurídica constituida que ya tiene ventas, pero son de otro giro, diferente al producto/servicio considerado en el proyecto, ¿Puedo postular?", value: "Tengo una persona jurídica constituida que ya tiene ventas, pero son de otro giro, diferente al producto/servicio considerado en el proyecto, ¿Puedo postular?" });
    }
    
    return (
        <ul className={styles.examplesNavList}>
            {EXAMPLES.map((x, i) => (
                <li key={i}>
                    <Example text={x.text} value={x.value} onClick={onExampleClicked} />
                </li>
            ))}
        </ul>
    );
};
